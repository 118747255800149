* {
  margin: 0;
  padding: 0;
  text-align: center;
}

tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  /* font-size: 100%; */
  /* font-size: 0.1rem; */
  font-size: 50%;
  font: inherit;
  /* vertical-align: baseline; */
}

.container {
  /* margin: auto; */
  /* width: 700px; */

  display: flex;
  /* flex-direction: column; */
  justify-content: center;

  /* background-color: red; */
}

table {
  border-collapse: collapse;
  /* border-spacing: 0; */
}

.col-select {
  background-color: yellow;
}

th {
  height: 10px;
  border: 1px solid black;
  padding: 10px;
  background-color: lightgray;
}
td {
  /* 폰트 단위로 rem은 사용을 자제하자 */
  font-size: 15px;
  height: 10px;
  border: 1px solid black;
  padding: 10px;
}

html {
  height: 100vh;
}
#root {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* https://loading.io/css/ */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
