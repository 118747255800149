* {
  font-size: 16px;
  text-rendering: optimizeLegibility;
}

.container {
  // position: absolute;
  // top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  // justify-content: center;
  align-items: center;
  h2 {
    font-size: 18px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  label {
    margin-bottom: 7px;
  }
}

.login {
  width: 300px;
  background-color: #f6f8fa;

  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 1px solid #d8dee4;
  border-radius: 5px;
  padding: 30px 20px;
}

.login_id {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.login_input {
  width: 100%;
  line-height: 20px;
  border: 1px solid #d8dee4;
  border-radius: 6px;
  text-align: left;
  padding: 5px 12px;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #0969da;
    box-shadow: 0 0 0 3px rgba(9, 105, 218, 0.3);
  }
}

// .login_input:focus {
//   box-shadow: none;
// }

// .login_input_id {}
// .login_input_pw {}

.login_pw {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.login_login_button {
  // width: 100%;
  // background-color: #dcdcdc;
  font-weight: bold;
  cursor: pointer;

  height: 40px;
  border-radius: 8px;
  border: none;

  display: block;
  width: 100%;
  text-align: center;

  transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  transition-property: color, background-color, border-color;

  color: #ffffff;
  background-color: #2da44e;
  border-color: rgba(27, 31, 36, 0.15);

  &:hover {
    background-color: #2c974b;
    border-color: rgba(27, 31, 36, 0.15);
  }
}

.forgot_box {
  display: flex;
  width: 100%;
  margin-top: 20px;
  /* justify-content: space-around; */
  justify-content: space-between;
}

.login_send_button {
}
