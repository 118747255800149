/* .container {
  position: fixed;
} */

.gnb {
  /* padding: 10px 10px; */
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding: 0px 30px;

  box-sizing: border-box;
  background-color: white;
  height: 60px;
  border-bottom: lightgray solid 1px;

  color: grey;
  font-size: 15px;
  font-weight: 400;

  z-index: 10;
}

.gnb-left {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gnb-right {
  height: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.item {
  /* background-color: white; */
  padding: 12px 10px;
  border-radius: 8px;
  cursor: pointer;
}

.item:hover {
  background-color: whitesmoke;
  transition: background-color 0.3s;
}

.on {
  font-weight: 800;
  color: #191f18;
  background-color: whitesmoke;
}
