* {
  font-family: sans-serif;
}

.home {
  display: flex;
  margin-top: 70px;
  flex-direction: column;
  justify-content: center;
}

.home > h2 {
  font-size: 30px;
  font-weight: bolder;
  /* position: absolute; */
  /* top: 350px; */
  /* left: 300px; */

  animation: slide 2s ease-out;
}

@keyframes slide {
  from {
    opacity: 0;
    /* top: 350px; */
  }
  to {
    opacity: 1;
    /* top: 350px; */
  }
}

.not-found {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not-found > h2 {
  font-size: 27px;
  font-weight: bold;
}

.not-found > .bold {
  font-size: 27px;
  font-weight: bold;
}

.not-found > button {
  margin: 30px;
  width: 70px;
  height: 30px;
}
