.cotent-container {
  display: flex;
  flex-direction: column;
  padding-top: 60px;
}

.cotent-container.center {
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  height: calc(100% - 60px);
}

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  color: #191f28;
  margin-bottom: 60px;

  /* padding-top: 60px; */
  /* margin-bottom: 50px; */
}

a {
  display: block;
  text-decoration: none;
  color: #333;
}

h2 {
  font-size: 27px;
}

.doc-group-title {
  background-color: #f0f0f5;
}

.doc-group-title > h2 {
  font-size: 20px;
}

.doc {
  margin: 10px;
}

.doc-title {
  font-size: 14px;
}

.doc-desc {
  font-size: 14px;
  font-weight: 900;
}
